<script setup>
  import { ElMenuItem, ElSubMenu } from 'element-plus'
  import { useRouter } from 'vue-router'
  import { getCurrentInstance, watchEffect, inject } from 'vue'
  const { proxy } = getCurrentInstance()
  const router = useRouter()
  const findHierarchyId = inject('findHierarchyId')
  const props = defineProps({
    menuData: {
      type: Array
    }
  })
  const toList = (menu) => {
    if (!menu) return
    let num = proxy.JLTools.createCode(4)
    // console.log(menu)
    findHierarchyId(menu)
    if (menu.level == 1) {
      router.push({
        name: 'productList',
        query: {
          firstCategoryId: menu.id,
          id: num
        }
      })
    } else if (menu.level == 2) {
      router.push({
        name: 'productList',
        query: {
          secondCategoryId: menu.id,
          id: num
        }
      })
    } else {
      router.push({
        name: 'productList',
        query: {
          thirdCategoryId: menu.id,
          id: num
        }
      })
    }
  }
</script>
<template>
  <template v-for="item of menuData" :key="item.id">
    <template v-if="item.children && item.children.length > 0">
      <div class="rowSC menu-item">
        <div class="ico" :class="item.level !== 1 ? 'ml10' : ''" :style="{ backgroundImage: `url(${item.coverImg_w300})` }"></div>
        <el-sub-menu :teleported="true" :popper-offset="0" :index="item.id.toString()" @click="toList(item)">
          <template #title>{{ item.name }}</template>
          <sub-menu :menuData="item.children" />
        </el-sub-menu>
      </div>
    </template>
    <div v-else @click.stop="toList(item)">
      <div class="rowSC menu-item">
        <div class="ico" :class="item.level !== 1 ? 'ml10' : ''" :style="{ backgroundImage: `url(${item.coverImg_w300})` }"></div>
        <el-menu-item :index="item.id.toString()">
          {{ item.name }}
        </el-menu-item>
      </div>
    </div>
  </template>
</template>

<style scoped>
  .el-menu-item {
    height: 100%;
  }

  /* el-sub-menu__title 的 hover 状态 */
  :deep(.el-sub-menu__title:hover) {
    background-color: hsl(0, 0%, 100%);
  }

  /* el-menu--horizontal 类下的 el-menu-item 的 hover 状态 */
  :deep(.el-menu--horizontal .el-menu-item:not(.is-disabled):hover) {
    background-color: #fff;
  }

  /* el-menu-item 的 hover 状态 */
  :deep(.el-menu-item:hover) {
    background-color: #fff !important;
  }

  /* .menu-item 的 hover 状态下的 el-sub-menu__title */
  .menu-item:hover :deep(.el-sub-menu__title) {
    color: var(--btnBgColor);
  }
  .ico {
    width: 26px;
    height: 26px;
    margin-right: 6px;
    background-size: 100% 100%;
    filter: hue-rotate(220deg);
  }
</style>
