<template>
  <div class="content">
    <div class="main">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Content'
  }
</script>

<style scoped lang="less">
  .content {
    display: flex;
    flex-flow: column;
    width: 100%;

    > .main {
      position: relative;
      flex: 1;
      display: flex;
      flex-flow: column;
      width: 100%;
      margin: 0 auto;

      > :first-child {
        flex: 1;
      }
    }
  }
</style>
