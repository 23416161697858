<template>
  <div class="layout">
    <Header />

    <Content class="content">
      <router-view />
      <Footer />
    </Content>
  </div>
</template>

<script>
  import Header from './components/Header'
  import Content from './components/Content'
  import Footer from './components/Footer'

  export default {
    name: 'default',
    components: { Header, Content, Footer },
    data() {
      return {
        breadcrumb_list: ['TV&Home Appliances', 'Wearable Technology', 'Gesture Control', 'Goods Details']
      }
    }
  }
</script>

<style scoped lang="less">
  .layout {
    width: 100%;
    max-width: var(--maxWidth);
    margin: 0 auto;
  }
</style>
