<script setup>
  import Page from '@/components/Page'
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits, watch } from 'vue'
  import { urlCompletion } from '@/util/func'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore, HsystemStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  watch(
    () => HsystemStore.menuData,
    (newValue, oldValue) => {
      // console.log(newValue, 'HsystemStore.menuData')
      if (newValue.length) {
        newValue.forEach((element) => {
          element.typeName = element.name
          element.id = element.id
        })
        data.footerList[0].list = newValue
      }
    }
  )

  const footerList = [
    { typeName: 'Fast Express Delivery', introduce: 'Fast Express Delivery', image: 'https://cdn.pricoo.pk/28ee7d06.png' },
    { typeName: 'Free Shipping', introduce: 'Free Shipping', image: 'https://cdn.pricoo.pk/49f7b4fa.png' },
    { typeName: 'Secure Payment', introduce: 'Secure Payments <br/> Card Online Transfer', image: 'https://cdn.pricoo.pk/f74c433f.png' },
    { typeName: 'Easy Return', introduce: 'Easy Return & Refund <br/> 7 Days In Case of Damage Units', image: 'https://cdn.pricoo.pk/5eef5c7e.png' },
    { typeName: 'Quick Response', introduce: 'Hassle Free Service', image: 'https://cdn.pricoo.pk/93e81028.png' },
    { typeName: 'Original Products', introduce: '100% Original Product', image: 'https://cdn.pricoo.pk/a453de80.png' },
    { typeName: 'Discounts', introduce: 'Payments & Discounts', image: 'https://cdn.pricoo.pk/add299b9.png' }
  ]

  const data = reactive({
    footerList: [
      {
        title: 'HOUSEHOLD',
        list: []
      },
      {
        title: 'CUSTOMER CARE',
        list: [
          { typeName: 'Press/Media', introduce: '', image: '', url: 'https://www.haier.com/pk/about-haier/news/' },
          // { typeName: 'Store Locator', introduce: '', image: '', url: '' },
          // { typeName: 'FAQ', introduce: '', image: '', url: '' },
          { typeName: 'Contact Us', introduce: '', image: '', url: '/contactUs' },
          { typeName: 'Product Registration', introduce: '', image: '', url: 'https://www.haier.com/pk/service-support/product-registration/' },
          { typeName: 'Service Appointment', introduce: '', image: '', url: 'https://www.haier.com/pk/service-support/service-appointment/' },
          { typeName: 'Warranty Declaration', introduce: '', image: '', url: 'https://www.haier.com/pk/service-support/warranty-declaration/' },
          { typeName: 'Troubleshooting', introduce: '', image: '', url: 'https://www.haier.com/pk/service-support/self-service/' },
          { typeName: 'Demo Videos', introduce: '', image: '', url: 'https://www.haier.com/pk/service-support/demo-video/' },
          { typeName: 'Manual Download', introduce: '', image: '', url: 'https://www.haier.com/pk/service-support/product-guide/' }
        ]
      },
      {
        title: 'HAIER',
        list: [
          { typeName: 'Haier PK', introduce: '', image: '', url: 'https://www.haier.com/pk/about-haier/haier-pk/' },
          { typeName: 'Media kit', introduce: '', image: '', url: 'https://www.haier.com/pk/media-kit/' },
          { typeName: 'About Us', introduce: '', image: '', url: 'https://www.haier.com/pk/about-haier/haier-pk/' },
          // { typeName: 'Blog', introduce: '', image: '', url: '/blogs' },
          // { typeName: 'Track Order', introduce: '', image: '', url: '' },
          { typeName: 'Terms & Condition', introduce: '', image: '', url: '/PrivacyPolicy?type=TERMS_CONDITIONS' },
          { typeName: 'Privacy Policy', introduce: '', image: '', url: '/PrivacyPolicy?type=USER_PRIVACY' }
          // { typeName: 'Cookie Policy', introduce: '', image: '', url: '' },
          // { typeName: 'Sitemap', introduce: '', image: '', url: '' },
        ]
      }
    ]
  })

  const shareList = [
    { typeName: '相机', image: 'https://cdn.pricoo.pk/62f4d8a6.png', url: 'https://www.instagram.com/haierpakistanofficial/?igshid=hwas1ju5g2d' },
    { typeName: 'footBook', image: 'https://cdn.pricoo.pk/da147d5a.png', url: 'https://www.facebook.com/HaierPakistan/' },
    { typeName: '飞书', image: 'https://cdn.pricoo.pk/6447332f.png', url: 'https://twitter.com/HaierPakistan/' }
  ]

  let paymentList = [
    { type: 'COD', typeName: 'cash-on-delivery', name: '货到付款', url: require('@/assets/imgs/payment/cod.png'), btnName: 'Cash On Delivery', width: 25 },
    { type: 'EASY_PAISA', typeName: 'easy-paisa', name: 'EasyPaisa', url: require('@/assets/imgs/payment/easy-paisa.png'), btnName: 'EasyPaisa', width: 28 },
    { type: 'JAZZ_CASH', typeName: 'jazzcash', name: 'jazzcash', url: require('@/assets/imgs/payment/jazzcash.png'), btnName: 'JazzCash', width: 56 },
    { type: 'CARD', typeName: 'card', name: '卡支付', url: require('@/assets/imgs/payment/card.png'), btnName: 'Credit/Debit Card', width: 32 },
    // { type: 'Visa', typeName: 'visa', name: 'jazzcash', url: require('@/assets/imgs/payment/Visa.png'), btnName: 'Visa', width: 32 }
  ]

  const footIcoList = {
    haierIco: { url: 'https://cdn.pricoo.pk/047c2fe7.png', urlBlack: 'https://cdn.pricoo.pk/69f2fa0d.png' },
    kefuIco: { url: 'https://cdn.pricoo.pk/f5352afb.png', urlBlack: 'https://cdn.pricoo.pk/f7f06aaf.png' }
  }

  const handleLink = (item) => {
    if (item.id) {
      return toList(item)
    }
    const url = urlCompletion(item.url)
    window.open(url, '_blank')
  }

  const toList = (item) => {
    let num = proxy.JLTools.createCode(4)
    if (item.level == 1) {
      router.push({
        name: 'productList',
        query: {
          firstCategoryId: item.id,
          id: num
        }
      })
    } else if (item.level == 2) {
      router.push({
        name: 'productList',
        query: {
          secondCategoryId: item.id,
          id: num
        }
      })
    }
  }

  onMounted(() => {
    if (!data.footerList[0].list.length) {
      HsystemStore.getMenuDataList()
    }
  })
</script>

<template>
  <div v-if="HsystemStore.menuData">
    <!-- <div class="rowBC foot-list-box">
      <div v-for="(item, index) in footerList" :key="index" class="item-box columnCC">
        <div class="foot-ico" :class="item.typeName === 'Fast Express Delivery' ? 'foot-ico01' : ''" :style="{ backgroundImage: `url(${item.image})` }"></div>
        <p class="title">{{ item.typeName }}</p>
      </div>
    </div> -->
    <div class="footer">
      <Page>
        <div class="rowBS foot-content">
          <div class="foot-left">
            <div>
              <JLImg class="haier-image" :src="footIcoList.haierIco.urlBlack"></JLImg>
              <div class="haier-text">
                Haier has become the No. 1 home appliance brand in Pakistan. Currently, Haier Air Conditioner is ranked #1 in market share for numerous years along with Haier
                Washing Machine.
              </div>
            </div>
            <div class="rowSC mt20">
              <div class="rowSC kefu-box">
                <JLImg class="kefu-image" :src="footIcoList.kefuIco.urlBlack"></JLImg>
                <div class="ml10 tel-box">
                  <div>Have any question?</div>
                  <div class="tel mt5">(042) 111 142 437</div>
                </div>
              </div>
            </div>
          </div>
          <div class="foot-right rowSS">
            <div v-for="(item, g) in data.footerList" :key="g" class="item-box columnSS">
              <div class="title">{{ item.title }}</div>
              <div v-for="(itemLink, index) in item.list" :key="index" class="item" @click.stop="handleLink(itemLink)">
                {{ itemLink.typeName }}
              </div>
            </div>
            <div>
              <div class="title subheading-text">100% Guarantee Safe Checkout</div>
              <div class="rowSC payment-icon-box">
                <div v-for="(item, index) in paymentList" :key="index">
                  <!-- <div class="share-image mr10" :style="{ backgroundImage: `url(${item.url})` }"></div> -->
                  <img class="payment-selection-icon" :src="item.url" :alt="item.btnName" :style="{ width: `${item.width}px` }" />
                </div>
              </div>
              <div class="subheading-text mt30">FOLLOW US</div>
              <div class="rowSC">
                <div v-for="(item, index) in shareList" :key="index" @click="handleLink(item)">
                  <div class="share-image mr10" :style="{ backgroundImage: `url(${item.image})` }"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Page>
    </div>
    <div class="banquan-box">
      <div class="rowCC banquan-content">
        <div>Haier - © 2024 All Rights Reserved</div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .footer {
    width: 100%;
    /* background: #fff; */
    background-color: var(--mainBgColor);
    color: var(--boxBgColor);
    padding: 25px 0;
    .foot-content {
      width: var(--minWidth);
      margin: 0 auto 20px;
      align-items: stretch;
      .foot-left {
        display: flex;
        flex-direction: column;
        flex: 0 0 255px;
        .haier-image {
          width: 170px;
          background-size: 100% 100%;
        }
        .haier-text {
          font-size: 14px;
          line-height: 24px;
          opacity: 0.5;
          text-align: justify;
        }
        .kefu-image {
          width: 50px;
        }
        .tel-box {
          min-width: 120px;
        }
        .kefu-box {
          .tel {
            /* color: var(--btnBgColor); */
            cursor: pointer;
            font-size: 14px;
          }
        }
      }
      .item-box {
        flex-grow: 1;
        flex-basis: 0;
        height: 100%;
        margin-right: 60px;
        box-sizing: border-box;
        .title {
          font-size: 16px;
          margin: 10px 0;
          text-align: center;
          font-weight: bold;
          white-space: nowrap;
        }
        .item {
          font-size: 14px;
          opacity: 0.7;
          margin-top: 15px;
          cursor: pointer;
          white-space: nowrap;
          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
  .banquan-box {
    width: 100%;
    height: 54px;
    line-height: 54px;
    font-size: 13px;
    /* background-color: #f2f2f2;
    color: #999; */
    background-color: var(--mainBgColor);
    color: var(--boxBgColor);
    border-top: 1px solid rgba(196, 196, 196, 1);
    .banquan-content {
      width: var(--minWidth);
      margin: 0 auto;
      .item {
        cursor: pointer;
        opacity: 0.7;
      }
    }
  }

  .foot-list-box {
    width: var(--minWidth);
    margin: 20px auto 20px;
    font-size: 16px;
    .item-box {
      /* flex-grow: 1;
      flex-basis: 0; */
      height: 100%;
      padding: 0 15px;
      box-sizing: border-box;
      /* border-right: 1px solid #eee; */
      .title {
        margin: 10px 0;
        color: rgba(34, 34, 34, 0.87);
        text-align: center;
      }
      .introduce {
        width: 100%;
        height: 65px;
        margin-top: 10px;
        color: rgba(34, 34, 34, 0.87);
        text-align: center;
      }
      .foot-ico {
        width: 50px;
        height: 50px;
        background-size: 100%;
      }
      .foot-ico01 {
        width: 65px;
        height: 50px;
      }
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        border-right: none;
        padding-right: 0;
      }
    }
  }
  .foot-right {
    .subheading-text {
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      color: #fff;
      margin: 10px 0 12px 0;
    }
    .share-image {
      width: 40px;
      height: 40px;
      background-size: 100% 100%;
      cursor: pointer;
    }
    .payment-icon-box {
      margin: 0 8px;
      .payment-selection-icon {
        width: 16px;
        margin-right: 12px;
        vertical-align: middle;
      }
    }
  }
</style>
