<script setup>
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const userCenterRef = ref()
  const popoverRef = ref()

  const data = reactive({
    defaultHead: 'https://cdn.pricoo.pk/6bf8f9b3.png',
    userIco: '99cc92a4.png'
  })

  const userFacilityList = [
    { type: 'userinfo', typeName: 'My Profile', userSelect: 'userInformation', ico: 'https://cdn.pricoo.pk/f44912ff.png' },
    // { type: 'coupon', typeName: 'Coupon', userSelect: '', ico: 'https://cdn.pricoo.pk/d72516fc.png' },
    { type: 'userinfo', typeName: 'Favorites', userSelect: 'userMyAttention', ico: 'https://cdn.pricoo.pk/3bae5297.png' },
    // { type: 'userinfo', typeName: 'Settings', userSelect: 'userInformation', ico: 'https://cdn.pricoo.pk/6478d4bb.png' },
    // { type: 'userwallet', typeName: 'Wallet', userSelect: 'userWallet', ico: 'https://cdn.pricoo.pk/fff66212.png' },
    { type: 'login', typeName: 'Logout', userSelect: 'loginOut', ico: 'https://cdn.pricoo.pk/19aefdb4.png' }
  ]

  // 处理用户功能
  const handleFacility = (item) => {
    switch (item.type) {
      case 'userinfo':
        toUserInfo(item.userSelect)
        break
      case 'coupon':
        toCoupinIndex()
        break
      case 'userwallet':
        toUserwallet()
        break
      default:
        loginOut()
        break
    }
  }

  // 跳转个人中心-我的订单列表页面
  const toUserInfo = (page) => {
    userStore.setUserShowPage({ page: page, selectType: page })
    proxy.$router.push({
      path: '/USERINFO'
    })
  }

  // 跳转领劵中心
  const toCoupinIndex = () => {
    proxy.$router.push({
      path: '/couponIndex'
    })
  }

  // 跳转个人中心-我的订单列表页面
  const toUserwallet = (page) => {
    userStore.setUserShowPage({ page: page, selectType: page })
    proxy.$router.push({
      path: '/userwallet'
    })
  }

  const loginOut = () => {
    userStore.setAddressList([])
    userStore.setCollectProduct(0)
    userStore.setLoginOut()
    proxy.$router.replace({
      path: '/'
    })
  }

  const goLogin = () => {
    if (userStore.userInfo.userId) return
    proxy.$router.replace({
      path: '/login'
    })
  }

  //查询关注数量
  const queryAttentionNum = () => {
    if (JLTools.isNull(userStore.userInfo.userId)) return
    let parms = {
      'collected.equals': true,
      'logicalDeleted.equals': false,
      'userId.equals': userStore.userInfo.userId,
      size: 1000
    }
    api.product.queryAttentionNum(parms).then((res) => {
      userStore.setCollectProduct(res)
    })
  }

  onMounted(() => {
    queryAttentionNum()
  })
</script>

<template>
  <div class="user-center-content">
    <div class="user-center-box" :class="userStore.userInfo.userId ? 'logged-box' : ''">
      <!-- <JLImg class="user-head" ref="userCenterRef" :src="userStore.userInfo.headImg ? userStore.userInfo.headImg : data.defaultHead"></JLImg> -->
      <div class="rowSC user-item" @click="goLogin">
        <!-- <div class="rowCC custom-radio" v-if="userStore.userInfo.headImg">
          <JLImg class="ico" :src="userStore.userInfo.headImg"></JLImg>
        </div> -->
        <JLImg class="ico" :src="userStore.userInfo.headImg || data.userIco"></JLImg>
        <div>
          <div class="user-name text_hide">{{ userStore.userInfo.nickname || 'User' }}</div>
        </div>
      </div>

      <div class="user-box">
        <div class="nickname text_hide">{{ userStore.userInfo.nickname }}</div>
        <div v-for="(item, index) in userFacilityList" :key="index" class="item rowSC" @click="handleFacility(item)">
          <JLImg class="ico" :src="item.ico" :lazy="false"></JLImg>
          <div>{{ item.typeName }}</div>
        </div>
      </div>
      <!-- <el-popover ref="popoverRef" :virtual-ref="userCenterRef" trigger="hover" virtual-triggering>
        <div>用户信息</div>
      </el-popover> -->
    </div>
  </div>
</template>

<style lang="less" scoped>
  .user-center-content {
    .user-head {
      cursor: pointer;
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    .user-center-box {
      position: relative;
    }
    .logged-box {
      &:hover {
        .user-box {
          display: block;
        }
      }
    }
  }

  .user-box {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    width: 180px;
    min-height: 100px;
    background: #fff;
    color: #515b6f;
    border-top: 0px;
    border-radius: 6px;
    z-index: 1;
    .nickname {
      padding: 24px;
      text-align: center;
      color: #333;
      font-weight: 500;
      font-size: 16px;
    }
    .item {
      cursor: pointer;
      margin-bottom: 18px;
      .ico {
        width: 24px;
        height: 24px;
        margin: 0 10px 0 24px;
      }
    }
  }

  .user-item {
    padding: 0 0px 0 20px;
    line-height: 24px;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    .ico {
      width: 20px;
      height: 20px;
      margin-right: 10px;
      background-size: 100% 100%;
    }
    .custom-radio {
      position: relative;
      display: inline-block;
      width: 22px; /* 调整大小 */
      height: 22px; /* 调整大小 */
      border: 2px solid #fff; /* 设置边框 */
      border-radius: 50%; /* 使其变成圆形 */
      margin-right: 10px;
      .ico {
        width: 16px;
        height: 16px;
        margin-right: 0px;
        background-size: 100% 100%;
      }
    }
    .user-name {
      max-width: 100px;
      font-weight: 300;
    }
  }
</style>
